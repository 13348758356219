<template>
  <div class="w-100 p-l-30 p-r-30">
    <div v-if="addNewBulkPartyScreen">
      <div class="contents-header">
        <p class="breadcrumb-menu">
          <router-link :to="{ name: 'TourAdminsIp', query: this.createSearchQuery()}"
                       class="ashen-link">大会管理者/関係者管理</router-link>
          <i class="fas fa-angle-right form-control-color"></i>関係者一括新規登録
        </p>
      </div>

      <div class="card mt-3">
        <div class="card-header bg-transparent">
          <img src="../assets/images/racket-gray.png"/>
          <span class="mx-3">{{ tournamentName }}</span>
        </div>
        <div class="card-body mt-5 mb-4">
          <div class="text-center">
            <div>
              <button class="btn btn-primary btn-download height-44">
                <img class="template-icon"
                     src="../assets/images/document-text.png"
                /><a class="text-decoration-none text-light" download
                     href="/download/batch_template_ip.xlsx">テンプレートファイルのダウンロード</a>
              </button>
            </div>
            <div class="input-group input-group-file mt-5 mx-auto">
              <input
                  class="form-control selected-file"
                  placeholder="ファイル選択"
                  type="text"
              />
              <span class="input-group-btn">
                <span class="btn btn-file">
                  <img src="../assets/images/arrow-bar-up.svg">
                  <input ref="file"
                         accept=".xls, .xlsx"
                         type="file"
                         @change="fileSelected"/></span
                ></span>
            </div>
            <div class="mt-5 row button-container btn-container mx-auto">
              <div class="col p-l-0">
                <button class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100"
                        @click="backToTourAdminSip">キャンセル
                </button>
              </div>
              <div class="col p-r-0">
                <button
                    :disabled="Object.keys(this.file).length === 0 && this.file.constructor === Object"
                    class="btn col btn-primary btn-outline-light font-weight-bold w-100"
                    @click="batchRegistrationConfirmation">登録
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="batchRegistrationConfirmationScreen">
      <div class="contents-header">
        <p class="breadcrumb-menu">
          <a class="ashen-link router-link-active"
             @click="backToTourAdminSip">大会管理者/関係者管理</a>
          <i class="fas fa-angle-right form-control-color"></i>関係者一括新規登録
        </p>
      </div>
      <div class="card mt-3">
        <div class="card-header bg-transparent">
          <img src="../assets/images/racket-gray.png"/>
          <span class="mx-3">{{ tournamentName }}</span>
        </div>
        <div class="card-body">
          <div class="text-center">
            <div>
              <p>一括登録を実行します。よろしいですか？ <br>処理の終了には数分かかる場合があります。</p>
            </div>
            <div class="row mt-5">
              <div class="col">
                <button :disabled="loading" class="btn btn-light bg-white ashen-link text-decoration-none light w-100"
                        @click="addBulkTourAdminSip">キャンセル
                </button>
              </div>
              <div class="col">
                <button :disabled="loading" class="btn col btn-primary btn-outline-light font-weight-bold w-100"
                        @click="submitFile">
                  登録
                </button>
              </div>
            </div>
            <div v-if="loading" class="row mt-5">
              <p>処理中です。処理の終了には数分かかる場合があります。</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="batchRegistrationSuccessMsgScreen">
      <p class="font-13">
        <a class="text-decoration-underline cursor-pointer"
           @click="backToTourAdminSip"
        >大会管理者/関係者管理</a
        ><i class="fas fa-angle-right form-control-color"></i>関係者一括新規登録
      </p>
      <div class="card mt-3">
        <div class="card-header bg-transparent">
          <img src="../assets/images/racket-gray.png"/>
          <span class="mx-3 font-16-medium"
          >{{ tournamentName }}</span
          >
        </div>
        <div class="card-body mx-auto">
          <div class="d-flex text-center flex-column">
            <div v-for="mes in uploadResponseMessage">
              <p>{{ mes }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {apiConfig, app, datatable, gender, defaults} from "@/constants";
import Datatable from "../components/datatable/Datatable";
import {mapGetters} from "vuex";
import {AdminUploadResult} from "../assets/jsadminclient";
import Common from "jsadminclient/common";
import Modal from '../components/modal/Modal'

export default {
  name: "TourAdminsIpBatch",
  props: {
    tournamentId: {type: Number, default: null}
  },
  components: {Datatable, Modal},
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      adminType: defaults.adminType,
      adminTypes: defaults.adminTypes,
      accountStatus: defaults.accountStatus,
      accountStatuses: defaults.accountStatuses,
      deleteSuccess: false,
      deleteFailed: false,
      gender: gender,
      loading: false,
      addNewBulkPartyScreen: false,
      batchRegistrationConfirmationScreen: false,
      batchRegistrationSuccessMsgScreen: false,
      columns: datatable.columns.touradminsip,
      tournamentId: "",
      tournamentList: {},
      file: {},
      tournamentName: '',
      ipUserId: "",
      nextUserId: '',
      nextUserName: '',
      prevUserId: '',
      prevUserName: '',
      coronaVaccinationStatus: "",
      pcrTestResultStatus: '',
      ipUser: {},
      roles: {},
      roleId: defaults.roleId,
      user: {},
      reloadKey: 1,
      year: "",
      month: "",
      date: "",
      uploadResponseMessage: [],
      errors: []
    };
  },
  computed: {
    ...mapGetters(["config"]),
  },
  mounted() {
    this.apiConfig.headers["X-API-Token"] = this.config.token;
    this.addBulkTourAdminSip()
    this.getTournament()
    this.loading = false
  },
  methods: {
    addBulkTourAdminSip() {
      this.file = {}
      this.batchRegistrationConfirmationScreen = false
      this.addNewBulkPartyScreen = true;
    },
    backToTourAdminSip() {
      this.addNewBulkPartyScreen = false;
      this.batchRegistrationConfirmationScreen = false;
      this.batchRegistrationSuccessMsgScreen = false;
      this.$router.push({name: "TourAdminsIp", query: this.createSearchQuery()});
    },
    batchRegistrationConfirmation() {
      this.batchRegistrationConfirmationScreen = true;
      this.batchRegistrationSuccessMsgScreen = false;
      this.addNewBulkPartyScreen = false;
    },
    batchRegistrationSuccessMsg() {
      this.batchRegistrationSuccessMsgScreen = true;
      this.addNewBulkPartyScreen = false;
      this.batchRegistrationConfirmationScreen = false;
    },
    fileSelected() {
      this.file = this.$refs.file.files[0];
      $(".selected-file").val(this.$refs.file.files[0].name)
    },
    async submitFile() {
      try {
        this.loading = true
        const api = new AdminApi.TournamentApi(this.getApiConfig());
        var result = await api.tournamentsTournamentIdIpUsersUploadPostAsync(
            this.tournamentId,
            this.file
        );
        if (result) {
          this.uploadResponseMessage.splice(0, this.uploadResponseMessage.length)

          let res = AdminUploadResult.createFromJson(result)
          let mes = res.successCount + "件　登録しました。"
          if (res.updated) {
            this.uploadResponseMessage.push(mes)
          }

          if (res.failureCount > 0) {
            mes = res.failureCount + "件　エラーになりました。"
            this.uploadResponseMessage.push(mes)
          }

          res.failureReasons.forEach((item) => {
            this.uploadResponseMessage.push(item)
          })
          this.batchRegistrationSuccessMsg();
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getRoles() {
      try {
        const api = new AdminApi.SystemApi(this.getApiConfig());
        var result = await api.systemRolesGetAsync();
        this.roles = result.items;
      } catch (e) {
        this.roles = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getTournament() {
      try {
        const api = new AdminApi.TournamentApi(this.getApiConfig());
        var result = await api.tournamentsListTournamentIdGetAsync(
            this.tournamentId,
        );
        this.tournamentName = result.tournamentName
      } catch (e) {
        this.tournamentName = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      );
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
}

.template-icon {
  margin: 0 8px;
}

.delete {
  width: 160px;
  height: 44px;
}

.select-role.form-control {
  background: #daced1;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.select-tournament.form-control {
  background: #f2ecf0;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.form-check {
  padding-left: 33px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #E0B1B7;
  height: 48px;
  line-height: 30px;
  border-radius: 0 4px 4px 0;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  //padding: 0px 190px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  background: #f2ecf0;
  border: none;
}

::placeholder {
  color: #888888;
}

.custom-button {
  background: #c95d6c;
  color: #ffffff;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

.yes {
  //width: 225px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.no {
  //width: 225px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.ok {
  //width: 414px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok:active {
  color: white;
  background-color: #c95d6c;
}

.cancel {
  //width: 414px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel:active {
  color: #c95d6c;
  background-color: #ffffff;
}

a {
  color: #333333;
}

@media only screen and (min-width: 800px) {
  .card {
    &-body {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  .btn, .input-group {
    &-download {
      width: 320px;
    }

    &-file, &-container {
      max-width: 745px !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .card {
    &-body {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.touradminsipbatch {
  .btn {
    &-primary {
      &:disabled {
        pointer-events: none;
        opacity: 0.65;
        background-color: #C95D6C;
      }
    }
  }
}
</style>
